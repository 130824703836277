(function () {
  'use strict';

  /**
   * @ngdoc neo.services
   * @name neo.services.auth.factory:Authentication
   *
   * @description
   *
   */
  angular
    .module('neo.services.auth')
    .factory('AuthenticationService', AuthenticationService);

  function AuthenticationService($http, $q, EnvironmentConfig) {
    var AuthenticationBase = {};
    AuthenticationBase.login = login;
    AuthenticationBase.logout = logout;
    return AuthenticationBase;


    function login(username, password) {
      var deferred = $q.defer();

      $http.post(EnvironmentConfig.API + '/auth/login',
        {username: username, password: password}, {
          skipAuthorization: true
        })
        .then(function (response) {
          // login successful if there's a token in the response
          var authorization = response.headers('Authorization');
          if (authorization) {
            var token = authorization.replace('Bearer ', '');
            // store username and token in local storage to keep user logged in between page refreshes
            localStorage.setItem('id_token', token);

            // add jwt token to auth header for all requests made by the $http service
            $http.defaults.headers.common.Authorization = authorization;

            // execute callback with true to indicate successful login
            deferred.resolve();
          } else {
            // execute callback with false to indicate failed login
            deferred.reject(response);
          }
        }, function (response) {
          deferred.reject(response);
        });
      return deferred.promise;
    }

    function logout() {
      // remove user from local storage and clear http auth header
      delete localStorage.removeItem('id_token');
      $http.defaults.headers.common.Authorization = '';
    }
  }

}());
